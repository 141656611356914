<template>
  <div class="my-second info bgcolor">
      <Head :title="$t('info.bind_email')" :show="true" :right="true" :cancel="true" >
          <span class="head-success" @click="onFinish">{{$t('info.complate')}}</span>
      </Head>
        <div class="common-box info-box">
            <van-form>
                <van-field   class="my-nickname" v-model="email" :placeholder="$t('info.input_email_address')" clearable />
                <van-field   v-if="this.$route.query.email" class="my-nickname my-code" v-model="code" :placeholder="$t('info.input_email_code')" clearable />
                <van-button  v-if="this.$route.query.email" class="confirm-btn margin10" @click="getEmailCode"> {{$t('info.get_email_address')}} </van-button>
            </van-form>
          
        </div>
  </div>
</template>

<script>
// 邮箱正则  
import {myinfo} from "@/common"
import {Notify,Toast} from "vant"
export default {
    mixins:[myinfo],
    data(){
        return {
            email:"",
            code:""
        }
    },
    methods:{
        getEmailCode(){
            if(this.email){
                this.$ajax.sendemail({
                    type:5,
                    email:this.email
                })
                .then(res=>{
                    
                })
            }else{
               Toast(this.$t('info.email_address_noempty'))
           }
        },
        onFinish(){
           console.log(this.user)
           if(this.email){
                //   判断是否第一次修改
                if(!this.$route.query.email){
                    // 第一次修改 
                    this.$ajax.setEmail({
                        email:this.email,
                    })
                    .then(res=>{
                        if(res.code==200){
                            this.setdashboard({...this.user,email:this.email});
                            Notify({ type: 'success', message: res.msg });
                            this.$router.go(-1)
                        }
                    })
                }else{
                    // 多次
                    this.$ajax.updateemail({
                        email:this.email,
                        code:this.code,
                    })
                    .then(res=>{
                        if(res.code==200){
                            this.setdashboard({...this.user,email:this.email});
                            Notify({ type: 'success', message: res.msg });
                            this.$router.go(-1)
                        }
                    })
                }
           }else{
               Toast("邮箱地址不能为空")
           }
            
       
        
        }
    },
    mounted(){
        this.email = this.$route.query.email
    }
}
</script>


